import { Deserializable } from './deserializable.interface';

export class Email implements Deserializable {
  to: string[];
  cc: string[];
  importance: string;
  body: string;
  objet: string;
  pjs: any[];

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
