export class ColumnModel {
/** List of options */
  id: number;
  key: string;
  canSort: boolean;
  order: number;
  propertyType: string;
  isAffiche: boolean;

  constructor(options: Partial<ColumnModel> = {}) {
    this.id = options.id;
    this.key = options.key;
    this.canSort = options.canSort || false;
    this.order = options.order || 0;
    this.propertyType = options.propertyType;
    this.isAffiche = options.isAffiche || false;
  }
}
