import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },

  {
    path: 'portail',
    component: LoginComponent,
    pathMatch: 'full'
  },

  {
    path: 'portail/:guid',
    component: LoginComponent,
    pathMatch: 'full'
  },

  {
    path: 'error',
    component: ErrorComponent,
    pathMatch: 'full'
  },

  {
    path: 'espace-finance',
    loadChildren: () => import('./espace-finance/espace-finance.module').then(mod => mod.EspaceFinanceModule),
    canActivate: [AuthGuard]
  },

  {
    path: '**',
    redirectTo: 'portail',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
