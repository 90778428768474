import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

export interface ILoggerService {
  info(value: any, ...rest: any[]): void;
  log(value: any, ...rest: any[]): void;
  warn(value: any, ...rest: any[]): void;
  error(value: any, ...rest: any[]): void;
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService implements ILoggerService {
  constructor(private configService: ConfigService) {
  }

  info(value: any, ...rest: any[]): void {
    if (this.configService.getAuthorizeLogging())
      console.info(value, rest);
  }

  log(value: any, ...rest: any[]): void {
    if (this.configService.getAuthorizeLogging())
      console.log(value, rest);
  }

  warn(value: any, ...rest: any[]): void {
    if (this.configService.getAuthorizeLogging())
      console.warn(value, rest);
  }

  error(value: any, ...rest: any[]): void {
    if (this.configService.getAuthorizeLogging())
      console.error(value, rest);
  }
}
