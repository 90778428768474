<div class="sub-header">
  <span class="col-md-12">
    &nbsp;
  </span>
</div>

<div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="isRequesting">
  <span class="sr-only">{{'Loading' | translate}}</span>
</div>

<div class="welcome-content" *ngIf="!isRequesting">

  <span *ngIf="welcomeMessage" [innerHTML]="welcomeMessage"></span>

  <div class="welcome-content-button">
    <button class="title-button colored-button" mat-button (click)="enter()">
      {{'User.Welcome.Enter' | translate}}
      <fa-icon [icon]="faPowerOff"></fa-icon>
    </button>
  </div>
  <div class="welcome-content-never-button">
    <button class="title-button colored-button" mat-button (click)="enter(true)">
      {{'User.Welcome.Ne.Plus.Afficher' | translate}}
    </button>
  </div>
</div>
