import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { cloneDeep } from "lodash";
import { ColumnModel } from '../../decorators/column.model';
import { sortBy } from "lodash";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { GridDisplayPipe } from 'src/app/shared/pipes/grid-display.pipe';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  providers: [GridDisplayPipe]
})
export class ExportComponent implements OnInit {
  @Input() loading: boolean = true;
  @Input() isPDF: boolean = false;
  @Input() isExcel: boolean = false;
  @Input() languageCode: string;
  @Input() showData: boolean = false;

  @Output() exportCompleted = new EventEmitter(true);

  columns: ColumnModel[];
  displayedColumns: string[];
  widthCol: any;

  @ViewChild('tablePDF', { read: ElementRef }) tablePDF: ElementRef;

  private _pdfSourceData: PagedResult<any>;
  @Input() set pdfSourceData(values: PagedResult<any>) {
    if (values && values.results) {
      values.results = values.results;
      this._pdfSourceData = cloneDeep(values);
      this.buildPagedColumns();
      if (this.showData == false) {
        if (this.isPDF == true) {
          this.downloadPDF();
        } else if (this.isExcel == true) {
          this.exportAsExcel();
        }
      }
    }
  }
  get pdfSourceData(): PagedResult<any> {
    return this._pdfSourceData;
  }


  constructor(private gridDisplayPipe: GridDisplayPipe, private consoleLoggerService: ConsoleLoggerService) { }

  ngOnInit(): void {
  }

  private buildPagedColumns() {
    this.columns = this._pdfSourceData.columns.map(i => new ColumnModel({ id: this._pdfSourceData.columns.indexOf(i), key: i.colonneLibelle, canSort: false, order: i.ordre, propertyType: i.colonneTypeCode, isAffiche: i.isAffiche }));
    this.sortColumns(); // Sort columns by order
    this.displayedColumns = this.columns.map(col => col.key);
    this.widthCol = (100 / this.displayedColumns.length) + "%";
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ["order"]);
  }

  public downloadPDF(): void {
    this.loading = true;
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
      compress: true
    });

    let body = this.pdfSourceData.results.map(i => this.gridDisplayPipe.transformArray(i, this.languageCode, this.columns.map(k => k.propertyType)));

    let columnsStyle = this.columns.map(i => (i.propertyType == 'I' || i.propertyType == 'N') ? 'right' : 'left');
    let styles = {};
    columnsStyle.forEach((i, index) => styles[index] = { halign: i });

    autoTable(doc, {
      head: [this.columns.map(i => i.key)],
      body: body,
      columnStyles: styles,
      //startY: 15,
      //margin: { horizontal: 0 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' }
    });

    this.loading = false;
    doc.save('export.pdf');
    //doc.output('dataurlnewwindow');

    this.completeExport();
  }

  exportAsExcel() {
    let datas = cloneDeep(this.pdfSourceData.results);
    //this.consoleLoggerService.log('datas before unshift', datas);
    //this.consoleLoggerService.log('datas unshifted', datas);
    //let datas = this.pdfSourceData.results.map(i => this.gridDisplayPipe.transformArray(i, this.languageCode, this.columns.map(k => k.propertyType)));

    let datasToExport = [];

    datas.map((line, indexLine) => {
      this.columns.map((column, indexColumn) => {
        if (column.isAffiche) {
          if (!datasToExport[indexLine]) {
            datasToExport[indexLine] = {};
          }
          //this.consoleLoggerService.log(column.key, indexLine, indexColumn, line, line[indexColumn]);
          datasToExport[indexLine][indexColumn - 1] = line[indexColumn - 1];
        }
      })
    });

    let header = {};
    this.columns.map((column, indexColumn) => {
      if (column.isAffiche) {
        header[indexColumn - 1] = column.key;
      }
    });

    datasToExport.unshift(header);

    this.consoleLoggerService.log('datas to export', datasToExport);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datasToExport, { skipHeader: true });
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    /* save to file */
    XLSX.writeFile(wb, 'SheetJS.xlsx');

    this.completeExport();
  }

  completeExport() {
    if (this.showData == false) {
      this.clean();
      this.exportCompleted.emit();
    }
  }

  clean() {
    this.pdfSourceData = null;
    this.displayedColumns = null;
  }
}
