import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { QueryParameter } from 'src/app/shared/models/query-parameter.model';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { cloneDeep } from "lodash";
import { ColumnModel } from 'src/app/shared/decorators/column.model';
import { sortBy } from "lodash";
import { SelectionModel } from '@angular/cdk/collections';
import { faFilePdf, faFileExcel, faFileWord, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { ExportComponent } from '../export/export.component';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';
import { ClientService } from '../../services/client.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() dataAvailable: Boolean = false;
  @Input() loading: boolean = true;
  @Input() IsInfiniteScroll: boolean = true;
  @Input() filter: QueryParameter;
  @Input() pieces: any[];
  @Input() loadingText: string = '';
  @Input() unavailableText: string = '';
  @Input() selection = new SelectionModel<any>(true, []);
  @Input() languageCode;
  selectionAll = new SelectionModel<any>(true, []);
  isAllResultsSelected = false;
  columnNPieceIndex: number = 0;
  columnFacIDIndex: number = 0;
  columnIsCheminIndex: number = 0;
  isDownloadingPDF = false;
  isDownloadingExcel = false;

  @Output() searchEvent = new EventEmitter();
  @Output() cleanEvent = new EventEmitter();
  @Output() selectionEvent = new EventEmitter<SelectionModel<any>>();
  @Output() selectAllEvent = new EventEmitter();
  @Output() scrollSearchEvent = new EventEmitter<number>();

  paginatorRowCount: number;
  paginatorPageSize: number;

  isScrolling = false;

  /**** FontAwesome Icons ****/
  faFilePdf = faFilePdf;
  faFileExcel = faFileExcel;
  faFileWord = faFileWord;
  faQuestion = faQuestion;
  /**** END FontAwesome Icons ****/


  pageSizeOptions = [5, 10, 25, 100];
  maxPageSize = 100;
  maxPageCount = 10;

  scrollPageNumber: number;

  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('matTableGrid', { read: ElementRef }) matTable: ElementRef;
  @ViewChild('modalExport', { static: true }) modalExport: ExportComponent;

  columns: ColumnModel[];
  displayedColumns: string[];
  private _originalPagedData: PagedResult<any> = new PagedResult<any>();

  private _pagedList: PagedResult<any>;
  @Input() set pagedList(values: PagedResult<any>) {
    if (values && values.results) {
      this.loading = true;
      this.pagedScrolledList = null;
      this._pagedList = cloneDeep(values);
      if (!this._originalPagedData.results) {
        // Keep original data
        this._originalPagedData = cloneDeep(this._pagedList);
      }

      this.buildFilter();
      this.buildPagedColumns();
      this.columnNPieceIndex = this.pagedList.columns.findIndex(i => i.nomChamp == 'NPiece');
      this.columnFacIDIndex = this.pagedList.columns.findIndex(i => i.nomChamp == 'FAC');
      this.columnIsCheminIndex = this.pagedList.columns.findIndex(i => i.nomChamp == 'CHEMIN');
      this.RefreshDatas();

      if (!this.isPaginatorOptimized()) {
        this.optimizePaginator();
      }
    }
  }
  get pagedList(): PagedResult<any> {
    return this._pagedList;
  }

  private _pagedAllList: PagedResult<any>;
  @Input() set pagedAllList(values: PagedResult<any>) {
    if (values && values.results) {
      this._pagedAllList = cloneDeep(values);
      if (this.isDownloadSearch() == false) {
        this.masterToggle();
      }
      //else {
      //  if (this.isDownloadingPDF == true) {
      //    this.downloadPDFAfterLoading();
      //  } else if (this.isDownloadingExcel == true) {
      //    this.exportAsExcel();
      //  }
      //}
    }
  }
  get pagedAllList(): PagedResult<any> {
    return this._pagedAllList;
  }

  private _pagedScrolledList: PagedResult<any>;
  @Input() set pagedScrolledList(values: PagedResult<any>) {
    if (values && values.results) {
      this._pagedScrolledList = cloneDeep(values);
      this.scrollPageNumber++;
      this.paginatorPageSize = this.paginatorPageSize + this._pagedScrolledList.pageSize;

      this._pagedList.results = this._pagedList.results.concat(this._pagedScrolledList.results);
      this.isScrolling = false;
      this.RefreshDatas();
    }
  }
  get pagedScrolledList(): PagedResult<any> {
    return this._pagedScrolledList;
  }

  constructor(private translate: TranslateService,
    private changeDetectorRefs: ChangeDetectorRef,
    private consoleLoggerService: ConsoleLoggerService,
    private clientService: ClientService,
    private fileSaverService: FileSaverService) { }

  ngOnInit() {
  }

  //sortData(params: Sort) {
  //  const direction: SortDirection = params.direction;
  //  this._pagedList.results = direction
  //    ? orderBy(this._pagedList.results, [params.active], [direction])
  //    : this._originalPagedData;
  //}

  private isPaginatorOptimized() {
    return (this.pageSizeOptions[this.pageSizeOptions.length - 1] == this.pagedList.rowCount || this.pageSizeOptions[this.pageSizeOptions.length - 1] == this.maxPageSize);
  }

  private optimizePaginator() {
    let sortedPaginatorData = this.pagedList.paginatorData.sort((a, b) => a - b);
    this.pageSizeOptions = sortedPaginatorData ? sortedPaginatorData : this.pageSizeOptions;

    this.filter.page = 0;
    this.filter.pageSize = this.pageSizeOptions[0];

    this.search();
  }

  private buildPagedColumns() {
    this.columns = this._pagedList.columns.map(i => new ColumnModel({ id: this._pagedList.columns.indexOf(i), key: i.colonneLibelle, canSort: false, order: i.ordre, propertyType: i.colonneTypeCode, isAffiche: i.isAffiche }));
    this.sortColumns(); // Sort columns by order
    this.displayedColumns = this.columns.filter(i => i.isAffiche).map(col => col.key);
    this.displayedColumns.unshift('select');
  }

  private buildFilter() {
    this.filter.page = this.pagedList.currentPage;
    this.scrollPageNumber = this.pagedList.currentPage + 1;
    this.filter.pageSize = this.pagedList.pageSize;
    this.filter.listRowCount = this.pagedList.rowCount;

    this.maxPageCount = (this.pagedList.maxPageCount > 0) ? this.pagedList.maxPageCount : this.maxPageCount;
    this.maxPageSize = (this.pagedList.maxPageSize > 0) ? this.pagedList.maxPageSize : this.maxPageSize;

    if (this.filter.page == 0) {
      this.paginator.firstPage();
    }
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ["order"]);
  }

  RefreshDatas() {
    if (this.pagedList && this.pagedList.results.length > 0) {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = this.pagedList.results;
      this.paginatorRowCount = this.pagedList.rowCount;
      this.paginatorPageSize = (this.pagedScrolledList && this.pagedList.pageSize < this.pagedList.results.length) ? this.pagedList.results.length : this.pagedList.pageSize;
    }

    //this.cleanSelection();
    this.changeDetectorRefs.detectChanges();
    this.loading = false;

  }

  search() {
    this.loading = true;
    this.searchEvent.emit({ key: this.translate.instant('Filter.Search'), value: this.filter });
  }

  onPaginateChange(event) {
    this.consoleLoggerService.log('onPaginateChange', event);
    this.filter.page = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.scrollToTop();

    this.search();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.pagedList.rowCount;
    return numSelected >= numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (!this.isAllSelected()) {
      if (!this.pagedAllList || this.pagedAllList.rowCount != this.pagedList.rowCount) {
        this.selectAllEvent.emit();
      } else {
        this.pagedAllList.results.filter(i => this.selection.selected.findIndex(j => j[this.columnNPieceIndex] == i[this.columnNPieceIndex]) == -1).forEach(row => this.selection.select(row));
        this.isAllResultsSelected = true;
      }
    } else {
      this.selection.clear();
      this.isAllResultsSelected = false;
    }
    this.emitSelection();
  }

  isRowSelected(row) {
    return (this.selection.selected.findIndex(j => j[this.columnNPieceIndex] == row[this.columnNPieceIndex]) != -1);
  }

  cleanSelection() {
    this.selection = new SelectionModel<any>(true, []);
    this.isAllResultsSelected = false;
    this.scrollToTop();
    this.emitSelection();
  }

  selectionToggle(row) {
    if (this.loading == false) {
      let rowSelected = (this.selection.selected.findIndex(j => j[this.columnNPieceIndex] == row[this.columnNPieceIndex]) != -1) ? this.selection.selected[this.selection.selected.findIndex(j => j[this.columnNPieceIndex] == row[this.columnNPieceIndex])] : row;
      this.selection.toggle(rowSelected);
      this.emitSelection();
    }
  }

  emitSelection() {
    this.selectionEvent.emit(this.selection);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return this.isAllSelected() ? this.translate.instant('Grid.Deselect.All') : this.translate.instant('Grid.Select.All');
    }
    return this.selection.isSelected(row) ? this.translate.instant('Grid.Deselect.Row') : this.translate.instant('Grid.Select.Row');
  }

  onTableScroll(e) {
    if (this.IsInfiniteScroll == true) {
      const tableViewHeight = e.target.offsetHeight // viewport
      const tableScrollHeight = e.target.scrollHeight // length of all table
      const scrollLocation = e.target.scrollTop; // how far user scrolled

      // If the user has scrolled within 200px of the bottom, add more data
      const buffer = 400;
      const limit = tableScrollHeight - tableViewHeight - buffer;
      if (scrollLocation > limit && this.isScrolling == false && this.pagedList.results.length < this.pagedList.rowCount) {
        //this.consoleLoggerService.log('onTableScroll', e);
        this.isScrolling = true;
        this.scrollSearchEvent.emit(this.scrollPageNumber + 1);
      }
    }
  }

  scrollToTop() {
    this.matTable.nativeElement.scrollTo(0, 0);
  }

  onDownloadPDF() {
    this.isDownloadingPDF = true;
    this.modalExport.isPDF = true;
    this.modalExport.isExcel = false;
    setTimeout(() => {
      this.launchExport();
    });
  }

  onDownloadExcel() {
    this.modalExport.isPDF = false;
    this.modalExport.isExcel = true;
    this.isDownloadingExcel = true;
    this.launchExport();
  }

  launchExport() {
    this.consoleLoggerService.log(this.isDownloadingExcel, this.isDownloadingPDF);
    if (!this.pagedAllList || this.pagedAllList.rowCount != this.pagedList.rowCount) {
      this.selectAllEvent.emit();
    } else {
      this.modalExport.pdfSourceData = this.pagedAllList;
    }
  }

  exportCompleted() {
    this.isDownloadingPDF = false;
    this.isDownloadingExcel = false;
  }

  getIsCheminDuplicata(row: any) {
    let isChemin = row[this.columnIsCheminIndex];
    return isChemin;
  }

  isDownloadSearch() {
    return this.isDownloadingExcel || this.isDownloadingPDF;
  }

  DownloadPJ(row: any) {
    let factureID = row[this.columnFacIDIndex];
    let type = 'application/pdf';

    if (type) {
      this.clientService.getDocumentFileFromFacture(factureID).subscribe(data => this.downloadFile(data, factureID)),
        error => this.consoleLoggerService.log('Error downloading the file.'),
        () => this.consoleLoggerService.info('OK');
    } else {
      this.consoleLoggerService.info('Type non reconnu', document);
    }
  }

  downloadFile(data: any, factureID: string) {
    let blob = new Blob([data], { type: 'application/pdf' });
    this.fileSaverService.save(blob, 'duplicata.pdf');
  }


}
