import { autoserializeAs, autoserializeAsJson, autoserializeAsArray } from 'cerialize';
import { GridColumn } from './grid-column.model';

export class PagedResult<T> {
  @autoserializeAsJson() results: Array<T>;
  @autoserializeAsArray(GridColumn) columns: Array<GridColumn>;
  @autoserializeAs(Number) currentPage: number;
  @autoserializeAs(Number) pageCount: number;
  @autoserializeAs(Number) pageSize: number;
  @autoserializeAs(Number) maxPageCount: number;
  @autoserializeAs(Number) maxPageSize: number;
  @autoserializeAs(Number) rowCount: number;
  @autoserializeAs(Number) firstRowOnPage: number;
  @autoserializeAs(Number) lastRowOnPage: number;
  @autoserializeAsArray(Number) paginatorData: number[];
}
