import { Column } from '../decorators/column.decorator';
import { autoserializeAs } from "cerialize";

export class TypeKeyLabel {
  @autoserializeAs(String) type: string;
  @Column()
  @autoserializeAs(String) key: string;
  @Column()
  @autoserializeAs(String) label: string;
}
