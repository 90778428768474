import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/shared/services/client.service';
import { SetLanguageCode } from '../shared/store/user/user.action';
import { Store } from '@ngxs/store';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  isRequesting: boolean;
  guid: string;

  constructor(private aRoute: ActivatedRoute, private router: Router, private userService: UserService,
    private clientService: ClientService, protected store: Store, private consoleLoggerService: ConsoleLoggerService) {
    this.guid = this.aRoute.snapshot.params.guid;
    this.consoleLoggerService.log(this.aRoute.snapshot);
    if (this.guid) {
      this.login();
    } else {
      this.router.navigate(['/error']);
    }
  }

  login() {
    this.isRequesting = true;

    this.userService.login(this.guid)
      .pipe(finalize(() => this.isRequesting = false))
      .subscribe(
        result => {
          if (result) {
            this.setLanguage();
          }
        }, error => {
          this.router.navigate(['/error']);
        });
  }

  setLanguage() {
    this.isRequesting = true;

    this.clientService.getClient()
      .pipe(finalize(() => this.isRequesting = false))
      .subscribe(
        result => {
          if (result) {
            this.store.dispatch(new SetLanguageCode(result.langueCode));
            if (result.showWelcomeMessage) {
              this.router.navigate(['/home']);
            } else {
              this.router.navigate(['/espace-finance']);
            }
          }
        }, error => {
          this.router.navigate(['/error']);
        });
  }

}
