import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { QueryParameter } from '../models/query-parameter.model';
import { TypeKeyLabel } from '../models/type-key-label.model';
import { PagedResult } from '../models/paged-result.model';
import { Informations } from '../models/informations.model';
import { Client } from '../models/client.model';
import { DeserializeArray, SerializeJSON } from 'cerialize';
import { map } from 'rxjs/operators';
import { JsonArray } from 'cerialize/dist/util';
import { Document } from '../models/document.model';
import { Titre } from '../models/titre.model';
import { ContactClient } from '../models/contact-client.model';
import { Critere } from '../models/critere.model';
import { Adresse } from '../models/adresse.model';
import { Statut } from '../models/statut.model';
import { Suivi } from '../models/suivi.model';
import { Email } from '../models/email.model';
import { stringify } from '@angular/compiler/src/util';

@Injectable()

export class ClientService extends BaseService {

  constructor(private http: HttpClient, private configService: ConfigService) {
    super(http, configService);
  }

  getClient() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Client>(this.baseUrl + '/clients/client', httpOptionsAuthWithParams);
  }

  getLogo() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<any>(this.baseUrl + '/clients/logo', httpOptionsAuthWithParams);
  }

  getWelcomeMessage() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<string>(this.baseUrl + '/clients/welcome', httpOptionsAuthWithParams);
  }

  getMail() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<any>(this.baseUrl + '/clients/mail', httpOptionsAuthWithParams);
  }

  sendMail(email: Email) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    const formData = new FormData();
    email.pjs.forEach(z => formData.append('pjs', z));
    formData.append('body', email.body)
    email.cc.forEach(i => formData.append('cc[]', i));
    formData.append('importance', email.importance)
    formData.append('subject', email.objet)
    email.to.forEach(k => formData.append('to[]', k));

    return this.http.post<boolean>(this.baseUrl + '/clients/sendmail', formData, this.httpOptionsAuthWithoutContentType);
  }

  postWelcomeMessage() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.post(this.baseUrl + '/clients/welcomeNoMore', null, httpOptionsAuthWithParams);
  }

  getClientContacts() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get(this.baseUrl + '/clients/contacts', httpOptionsAuthWithParams).pipe(
      map((i: JsonArray) => DeserializeArray(i, TypeKeyLabel))
    );
  }

  getClientDocuments(queryParameter: QueryParameter) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders,
      params: <any>queryParameter.getQuery()
    };

    return this.http.get<PagedResult<Document>>(this.baseUrl + '/clients/documents', httpOptionsAuthWithParams);
  }

  getClientInformationsCles() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Informations>(this.baseUrl + '/clients/infos', httpOptionsAuthWithParams);
  }

  getClientEcritures(queryParameter: QueryParameter) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders,
      params: <any>queryParameter.getQuery()
    };

    return this.http.get<PagedResult<any>>(this.baseUrl + '/clients/ecritures', httpOptionsAuthWithParams);
  }

  getDocumentFile(document: Document) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders,
      responseType: 'blob' as 'json'
    };

    return this.http.get<any>(this.baseUrl + '/clients/download/' + document.documentId, httpOptionsAuthWithParams);
  }

  getDocumentFileFromFacture(factureID: number) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders,
      responseType: 'blob' as 'json'
    };

    return this.http.get<any>(this.baseUrl + '/clients/downloadFromFac/' + factureID, httpOptionsAuthWithParams);
  }

  updateContact(contact: ContactClient) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.post<any>(this.baseUrl + '/clients/update', SerializeJSON(contact), httpOptionsAuthWithParams);
  }

  getTitres() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Titre[]>(this.baseUrl + '/clients/titres', httpOptionsAuthWithParams);
  }

  getContacts() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<ContactClient[]>(this.baseUrl + '/clients/contactsclient', httpOptionsAuthWithParams);
  }

  getAdresses() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Adresse[]>(this.baseUrl + '/clients/adresses', httpOptionsAuthWithParams);
  }

  getStatut() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Statut>(this.baseUrl + '/clients/statut', httpOptionsAuthWithParams);
  }

  getSuivis() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Suivi[]>(this.baseUrl + '/clients/suivis', httpOptionsAuthWithParams);
  }

  getFilters() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<Critere[]>(this.baseUrl + '/clients/filters', httpOptionsAuthWithParams);
  }

  getReleveDeCompte() {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<PagedResult<any>>(this.baseUrl + '/clients/compte', httpOptionsAuthWithParams);
  }

}
