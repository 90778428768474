import { Pipe, PipeTransform } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';


@Pipe({
  name: 'isRowInSelection',
  pure: true
})

export class IsRowInSelectionPipe implements PipeTransform {
  transform(row: [], selection: SelectionModel<any>, columnToCheck: number): any {
    if (row && row.length > 0 && selection && selection.selected.length > 0) {
      return (selection.selected.findIndex(j => j[columnToCheck] == row[columnToCheck]) != -1);
    }
    return false;
  }
}
