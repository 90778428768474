import { Pipe, PipeTransform } from '@angular/core';
import { formatDate, formatNumber } from '@angular/common';

@Pipe({
  name: 'gridDisplay'
})

export class GridDisplayPipe implements PipeTransform {
  transform(item: any, languageCode: string, propertyType: string = 'C', numbersWithoutWhispaces: boolean = false): any {
    let nullChar = '-';

    if (item == null
      || item.toString() == ''
      || item.toString() == ' ') {
      return nullChar;
    }

    let formattedItem: string;

    switch (propertyType) {
      // propertyType 'D' : DATE
      case 'D': formattedItem = formatDate(item, 'shortDate', languageCode); break;
      // propertyType 'I' : INT
      case 'I':
        formattedItem = formatNumber(item, languageCode, '.0-0');
        if (numbersWithoutWhispaces == true) {
          formattedItem = formattedItem.replace(/\s/g, '');
        }
        break;
      // propertyType 'N' : DOUBLE
      case 'N':
        formattedItem = formatNumber(item, languageCode, '.2-2');
        if (numbersWithoutWhispaces == true) {
          formattedItem = formattedItem.replace(/\s/g, '');
        }
        break;
      // propertyType 'C' : CHAR
      default: formattedItem = item; break;
    }

    return formattedItem;
  }

  transformArray(items: any[], languageCode: string, propertyTypes: string[]) {
    if (items == null
      || items.length == 0) {
      return null;
    }

    let transformedItems = items.map(i => this.transform(i, languageCode, propertyTypes[items.indexOf(i)], true));

    return transformedItems;
  }
}
