import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tiretAsNull'
})

export class TiretAsNullPipe implements PipeTransform {
  transform(item: any): any {
    if (item == null || item.toString() == '' || item.toString() == ' ') return '-';
    return item;
  }
}
