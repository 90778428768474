import { Component } from '@angular/core';
import { ConsoleLoggerService } from './shared/services/console-logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  isExpanded = false;

  constructor(private consoleLoggerService: ConsoleLoggerService) {
  }

  toggle() {
    this.consoleLoggerService.log(this.isExpanded);
    this.isExpanded = !this.isExpanded;
  }

  ToggleMenu(event: any) {
    this.toggle();
  }
}
