<div class="mat-elevation-z8 data-table-empty text-primary" *ngIf="!dataAvailable">
  <div class="loader">
    <div class="spinner-border text-secondary" role="status" *ngIf="loading">
      <span class="sr-only">{{'Loading' | translate}}</span>
    </div>
    {{ unavailableText | translate}}
  </div>
</div>

<div class="grid" [hidden]="!dataAvailable">

  <div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="loading && !isDownloadSearch()">
    <span class="sr-only">{{'Loading' | translate}}</span>
  </div>

  <div class="col-md-12 grid-title">
    <span class="col-md-7 grid-title-text">
      <button class="button orange" mat-button [routerLink]="" matTooltip="{{ 'Payeur.Documents.Download.Excel' | translate }}" (click)="onDownloadExcel()">
        <fa-icon [icon]="faFileExcel" *ngIf="!isDownloadingExcel"></fa-icon>
        <div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="isDownloadingExcel">
          <span class="sr-only">{{'Loading' | translate}}</span>
        </div>
      </button>
      <button class="button green" mat-button [routerLink]="" matTooltip="{{ 'Payeur.Documents.Download.PDF' | translate }}" (click)="onDownloadPDF()">
        <fa-icon [icon]="faFilePdf" *ngIf="!isDownloadingPDF"></fa-icon>
        <div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="isDownloadingPDF">
          <span class="sr-only">{{'Loading' | translate}}</span>
        </div>
      </button>

      <span class="line"></span>
      <span class="title"><span translate>Ecritures.Title</span> <span *ngIf="pagedList && pagedList.results"> ({{pagedList.rowCount}})</span></span>
      <span class="line"></span>
    </span>

    <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="paginatorRowCount" [pageSize]="paginatorPageSize"
                   (page)="onPaginateChange($event)" class="col-md-5" showFirstLastButtons></mat-paginator>

  </div>

  <mat-table #matTableGrid [dataSource]="dataSource" (scroll)="onTableScroll($event)" class="mat-elevation-z8">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
          {{checkboxLabel()}}
        </mat-checkbox>
      </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <input type="checkbox" (click)="$event.stopPropagation()"
               color="primary"
               (change)="$event ? selectionToggle(row) : null"
               [checked]="isRowSelected(row)"
               [disabled]="loading"
               [matTooltip]="checkboxLabel(row)" />
        <button class="title-button colored-button col-sm-3 col-xl-3" mat-button [routerLink]=""
                *ngIf="getIsCheminDuplicata(row)" matTooltip="{{ 'Payeur.Documents.Download.Title' | translate }}" (click)="DownloadPJ(row)">
          <fa-icon [icon]="faFilePdf" class="pdf"></fa-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="column.key" *ngFor="let column of columns | gridVisibleColumns ">
      <ng-container>
        <mat-header-cell *matHeaderCellDef [class]="(column.propertyType == 'I' || column.propertyType == 'N') ? 'number': ''">
          {{ column.key }}
        </mat-header-cell>
      </ng-container>
      <mat-cell *matCellDef="let element" [class]="(column.propertyType == 'I' || column.propertyType == 'N') ? 'number': ''">
        {{ element[column.id] | gridDisplay:languageCode:column.propertyType}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionToggle(row)"
             [ngStyle]="loading && {'background-color': 'lightgrey'}"></mat-row>
  </mat-table>

</div>


<div id="myModalExport" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-primary" translate>PDF</h4>
        <button type="button" class="close" data-dismiss="modal">×</button>
      </div>
      <div class="modal-body">
        <app-export #modalExport [pdfSourceData]="pagedAllList" [languageCode]="languageCode" (exportCompleted)="exportCompleted()"></app-export>
      </div>
    </div>
  </div>
</div>
