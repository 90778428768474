import { NgModule } from '@angular/core';

//import { MatIconRegistry } from '@angular/material/icon';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
//import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
//import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
//import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
//import { MatDialogModule } from '@angular/material/dialog';
//import { MatExpansionModule } from '@angular/material/expansion';
//import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
//import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatProgressBarModule } from '@angular/material/progress-bar';
//import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
//import { MatSliderModule } from '@angular/material/slider';
//import { MatSlideToggleModule } from '@angular/material/slide-toggle';
//import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
//import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
//import { MatTreeModule } from '@angular/material/tree';
//import { MatMomentDateModule } from "@angular/material-moment-adapter";

@NgModule({
  declarations: [],
  imports: [MaterialModule.MODULE_LIST],
  exports: [MaterialModule.MODULE_LIST],
  providers: []
})
export class MaterialModule {
  static readonly MODULE_LIST = [
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatDividerModule,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatListModule
  ];
}

