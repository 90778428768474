import { Pipe, PipeTransform } from '@angular/core';
import { ColumnModel } from '../decorators/column.model';

@Pipe({
  name: 'gridVisibleColumns'
})

export class GridVisibleColumnsPipe implements PipeTransform {
  transform(item: ColumnModel[]): any {
    if (item == null
      || item.length < 1) {
      return item;
    }

    return item.filter(i => i.isAffiche);
  }
}
