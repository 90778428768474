import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { QueryParameter } from '../models/query-parameter.model';
import { LitigeType } from '../models/litige-type.model';
import { CommentaireType } from '../models/commentaire-type.model';
import { PromesseType } from '../models/promesse-type.model';

@Injectable()

export class TypeService extends BaseService {

  constructor(private http: HttpClient, private configService: ConfigService) {
    super(http, configService);
  }

  getLitigeTypes(queryParameter: QueryParameter) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<LitigeType[]>(this.baseUrl + '/types/litige', httpOptionsAuthWithParams);
  }

  getPromesseTypes(queryParameter: QueryParameter) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<PromesseType[]>(this.baseUrl + '/types/promesse', httpOptionsAuthWithParams);
  }

  getCommentaireTypes(queryParameter: QueryParameter) {
    let httpOptionsAuthWithParams = {
      headers: this.httpOptionsAuthHeaders
    };

    return this.http.get<CommentaireType[]>(this.baseUrl + '/types/commentaire', httpOptionsAuthWithParams);
  }
}
