import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserService } from '../services/user.service';
import { UserState } from '../store/user/user.state';
import { Select, Store } from '@ngxs/store';
import { UserStateModel } from '../models/user-state.model';
import { UserInfos } from '../models/user-infos.model';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  error: string = '';
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  @Select(UserState) state$: Observable<UserStateModel>;
  currentUser: UserInfos;

  constructor(private userService: UserService, private store: Store, private consoleLoggerService: ConsoleLoggerService) {
    this.state$.subscribe(
      (userData) => {
        //this.consoleLoggerService.log(userData);
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
  }
}
