import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Moment } from 'moment';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Injectable()

export class ActionService extends BaseService {

  constructor(private http: HttpClient, private configService: ConfigService, private consoleLoggerService: ConsoleLoggerService) {
    super(http, configService);
  }

  uploadFile(idAction: number, fileData: File) {
    const file = new FormData();
    file.append('file', fileData);

    this.consoleLoggerService.log('uploadFile', idAction, file);
    return this.http.post<any>(this.baseUrl + '/action/upload/' + idAction, file, this.httpOptionsAuthWithoutContentType);
  }

  savePromesse(motif: string, date: Moment, commentaire: string, factureIDs: string[]) {
    let action = { motif, date: date.toDate(), commentaire, factureIDs };

    this.consoleLoggerService.log('savePromesse', action);
    return this.http.post<number>(this.baseUrl + '/action/promesse', action, this.httpOptionsAuth);
  }

  saveLitige(motif: string, date: Moment, commentaire: string, factureIDs: string[]) {
    let action = { motif, date: date.toDate(), commentaire, factureIDs };

    this.consoleLoggerService.log('saveLitige', action);
    return this.http.post<number>(this.baseUrl + '/action/litige', action, this.httpOptionsAuth);
  }

  saveCommentaire(motif: string, date: Moment, commentaire: string, factureIDs: string[]) {
    let action = { motif, date: date.toDate(), commentaire, factureIDs };

    this.consoleLoggerService.log('saveCommentaire', action);
    return this.http.post<number>(this.baseUrl + '/action/commentaire', action, this.httpOptionsAuth);
  }
}
