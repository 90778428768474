import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { SetLogin, SetLogout } from '../store/user/user.action';
import { UserInfos } from '../models/user-infos.model';

@Injectable()

export class UserService extends BaseService {

  baseUrl: string = '';

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  httpOptions = { headers: this.headers };
  httpOptionsAuth = { headers: this.headers };
  protected httpOptionsRefresh = {};


  constructor(private http: HttpClient, private configService: ConfigService, protected store: Store) {
    super(http, configService);
  }

  register(email: string, password: string, firstName: string) {
    let us = {
      email:email,
      password: password,
      firstName: firstName
    };

    let body = JSON.stringify(us);

    return this.http.post<boolean>(this.baseUrl + "/accounts", body, this.httpOptions)
      .pipe(map(res => true))
      .pipe(catchError(
        this.handleError
      ));
  }

  login(token) {
    let params = new HttpParams().set("id", token);

    return this.http.get<any>(this.configService.getApiURI() + '/token/generate', { headers: this.headers, params: params })
      .pipe(map(res => {
        this.store.dispatch(new SetLogin(res));
        return true;
      }))
      .pipe(catchError(
        this.handleError
      ));
  }

  logout() {
    this.store.dispatch(new SetLogout);
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  refreshTokenCall() {
    return this.http.get<any>(this.baseUrl + '/AuthentRefresh', this.httpOptionsRefresh);
  }
}
