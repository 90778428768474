// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ConsoleLoggerService } from './shared/services/console-logger.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router, private toastr: ToastrService, private consoleLoggerService: ConsoleLoggerService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.consoleLoggerService.log('AuthGuard', this.userService.currentUser, this.userService.isLoggedIn());

    if (!this.userService.loggedIn) {
      this.router.navigate(['/error']);
      return false;
    }

    //// check if route is restricted by role
    //if (route.data.roles && route.data.roles.indexOf(this.userService.currentUser.role) === -1) {
    //  // role not authorised so logout and redirect to login
    //  this.toastr.error('Unauthorized', null);
    //  this.router.navigate(['/']);
    //  return false;
    //}

    // authorised so return true
    return true;

  }
}
