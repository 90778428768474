import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent {
  @Input() files: File[] = [];
  @Output() nameEvent = new EventEmitter();

  constructor(private consoleLoggerService: ConsoleLoggerService) { }

  onSelect(event) {
    this.consoleLoggerService.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.consoleLoggerService.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
}
