import { Deserializable } from "./deserializable.interface";
import { DynamicFilter } from './dynamic-filter.model';

export class QueryParameter implements Deserializable {
  public page: number = 1;
  public pageSize: number = 10;
  public listRowCount: number = 0;

  public name: string;
  public numeroPieces: string[];
  public echu: number = 1;
  public dynamique: DynamicFilter[];
  public dateDebut: string;
  public dateFin: string;

  public constructor() {
  }

  getQuery() {
    var queryObject = <any>{};
    if (this.page) queryObject.page = this.page;
    if (this.pageSize) queryObject.pageSize = this.pageSize;
    if (this.name) queryObject.name = this.name;

    if (this.numeroPieces) {
      let numerosCSV = '';
      this.numeroPieces.map(i => numerosCSV += i + ',');
      queryObject.numeroPieces = [numerosCSV];
    }
    if (this.echu) queryObject.echu = this.echu;
    if (this.dynamique && this.dynamique.length > 0) {
      queryObject.dynamicIds = [this.dynamique.map(i => i.id)];
      queryObject.dynamicValues = [this.dynamique.map(i => i.value)];
      queryObject.dynamicNomChamps = [this.dynamique.map(i => i.nomChamp)];
      queryObject.dynamicTypes = [this.dynamique.map(i => i.type)];
    }
    if (this.dateDebut) queryObject.dateDebut = this.dateDebut;
    if (this.dateFin) queryObject.dateFin = this.dateFin;

    return queryObject;
  }

  goToNewSearch() {
    this.page = 1;
  }

  clean() {
    this.name = null;
    this.numeroPieces = null;
    this.echu = 1;
    this.dynamique = [];
    this.dateDebut = null;
    this.dateFin = null;
  }

  deserialize(input: any): this {
    (<any>Object).assign(this, input);
    return this;
  }
}  
