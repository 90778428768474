import { State, Action, StateContext, Selector } from "@ngxs/store";
import { UserStateModel } from "src/app/shared/models/user-state.model";
import { SetLogin, SetLogout, SetLanguageCode } from "../user/user.action";
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    isLogged: false,
    token: '',
    langueCode: ''
  }
})

@Injectable()
export class UserState {
  constructor(private translate: TranslateService) { }

  @Action(SetLogin)
  setLogin(ctx: StateContext<UserStateModel>, { token }: SetLogin) {
    ctx.setState({
      isLogged: true,
      token,
      langueCode: ''
    });

    localStorage.setItem('storeUserInfos', JSON.stringify(ctx.getState()));
  }

  @Action(SetLogout)
  setLogout(ctx: StateContext<UserStateModel>) {
    ctx.setState({
      isLogged: false,
      token: null,
      langueCode: ''
    });

    localStorage.removeItem('storeUserInfos');
  }

  @Action(SetLanguageCode)
  setLanguageCode(ctx: StateContext<UserStateModel>, { languageCode }: SetLanguageCode) {
    const state = ctx.getState();
    languageCode = languageCode.trim().toLowerCase()

    ctx.setState({
      ...state,
      langueCode: languageCode
    });

    this.translate.use(languageCode);

    localStorage.setItem('storeUserInfos', JSON.stringify(ctx.getState()));
  }

  @Selector()
  static getUserState(state: UserStateModel) {
    return state;
  }

}
