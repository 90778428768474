import { autoserializeAs } from 'cerialize';

export class GridColumn {
  @autoserializeAs(Number) listeID: number;
  @autoserializeAs(Number) colonneID: number;
  @autoserializeAs(Number) ordre: number;
  @autoserializeAs(String) nomChamp: string;
  @autoserializeAs(String) colonneLibelle: string;
  @autoserializeAs(String) colonneTypeCode: string;
  @autoserializeAs(Boolean) isObligatoire: boolean;
  @autoserializeAs(Boolean) isAffiche: boolean;
}
