<div class="email-content">

  <div class="email">

    <div class="left col-2">
      <div class="col-12 error" *ngIf="errors">
        <span translate>Contacts.Email.Errors</span>
      </div>
      <button mat-button class="send col-12" (click)="sendEmail()">
        <div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="isRequesting">
          <span class="sr-only">{{'Loading' | translate}}</span>
        </div>
        <mat-icon *ngIf="!isRequesting">email</mat-icon>
        <span translate>Contacts.Email.Send</span>
      </button>
      <button #closeBtn mat-button class="col-12" data-dismiss="modal">
        <span translate>Contacts.Email.Cancel</span>
      </button>
    </div>

    <div class="right form-group col-10">
      <div class="row">
        <div class="title col-2" translate>
          Payeur.Contact.Email.To
        </div>
        <div class="col-10">
          <mat-form-field class="col-12">
            <mat-chip-list #chipListTo>
              <mat-chip *ngFor="let to of email.to | slice:0:10" [selectable]="chipSelectable"
                        [removable]="chipRemovable" (removed)="removeChipTo(to)">
                {{to}}
                <mat-icon matChipRemove *ngIf="chipRemovable">cancel</mat-icon>
              </mat-chip>
              <mat-chip *ngIf="email.to && email.to.length > 10" [selectable]="false" [removable]="false">
                {{ 'Payeur.Contact.Email.To' | translate:{ 'count': email.to.length - 10} }}
                <mat-icon matChipRemove *ngIf="chipRemovable">cancel</mat-icon>
              </mat-chip>
              <input matInput type="text" class="form-control"
                     id="to"
                     [matChipInputFor]="chipListTo"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="chipAddOnBlur"
                     (matChipInputTokenEnd)="addChipTo($event)" />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="title col-2" translate>
          Payeur.Contact.Email.Cc
        </div>
        <div class="col-10">
          <mat-form-field class="col-12">
            <mat-chip-list #chipListCc>
              <mat-chip *ngFor="let cc of email.cc | slice:0:10" [selectable]="chipSelectable"
                        [removable]="chipRemovable" (removed)="removeChipCc(cc)">
                {{cc}}
                <mat-icon matChipRemove *ngIf="chipRemovable">cancel</mat-icon>
              </mat-chip>
              <mat-chip *ngIf="email.cc && email.cc.length > 10" [selectable]="false" [removable]="false">
                {{ 'Payeur.Contact.Email.Cc' | translate:{ 'count': email.cc.length - 10} }}
                <mat-icon matChipRemove *ngIf="chipRemovable">cancel</mat-icon>
              </mat-chip>
              <input matInput type="text" class="form-control"
                     id="cc"
                     [matChipInputFor]="chipListCc"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="chipAddOnBlur"
                     (matChipInputTokenEnd)="addChipCc($event)" />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="title col-2" translate>
          Payeur.Contact.Email.Objet
        </div>
        <div class="col-10">
          <mat-form-field class="col-12">
            <input matInput type="text" class="form-control"
                   id="objet"
                   name="objet" objet required [(ngModel)]='email.objet' #objet="ngModel" />
          </mat-form-field>
        </div>
      </div>

    </div>

  </div>

  <div class="col-12 editor-upload">
    <div class="col-8">
      <div class="editor">
        <quill-editor theme="snow" [(ngModel)]="email.body"></quill-editor>
      </div>
    </div>

    <div class="upload col-4">

      <div class="row content email">
        <div class="title col-2" translate>
          Payeur.Contact.Email.Priority.Title
        </div>
        <div class="col-10">
          <mat-form-field class="col-12">
            <mat-select id="importance"
                        name="importance" importance [(ngModel)]='email.importance' #importance="ngModel" [(value)]="email.importance" class="form-control">
              <mat-option *ngFor="let priority of priorities" [value]="priority.code">
                {{priority.libelle | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      
      <app-document-upload [files]="files"></app-document-upload>
    </div>
  </div>

</div>
