<body>

  <app-header></app-header>

  <div class="container col-md-12">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

</body>
