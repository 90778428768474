import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Select } from '@ngxs/store';
import { UserState } from '../store/user/user.state';
import { UserStateModel } from '../models/user-state.model';
import { UserInfos } from '../models/user-infos.model';

export abstract class BaseService {  
  protected httpOptions = {};
  protected httpOptionsAuth = {};
  protected httpOptionsAuthWithoutContentType = {};
  protected httpOptionsHeaders: HttpHeaders;
  protected httpOptionsAuthHeaders: HttpHeaders;
  protected httpOptionsAuthWithoutContentTypeHeaders: HttpHeaders;
  protected baseUrl = '';
  protected baseTokenUrl = '';
  protected authToken = '';

  @Select(UserState) state$: Observable<UserStateModel>;
  currentUser: UserStateModel;
  loggedIn = false;
  currentVersionAPI = 'v1';

  constructor(http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getApiURI() + '/' + this.currentVersionAPI;
    this.baseTokenUrl = configService.getApiURI();
    this.authToken = localStorage.getItem('auth_token');
    this.state$.subscribe(
      (userData) => {
        if (userData) {
          this.currentUser = userData;
          this.loggedIn = userData.isLogged;
          if (userData.isLogged && userData.token) {
            this.authToken = userData.token;
            this.refreshHttpOptions();
          }
        }
      }
    );

    this.refreshHttpOptions();
  }

  refreshHttpOptions() {
    this.httpOptionsHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.httpOptionsAuthHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authToken}`
    });
    this.httpOptionsAuthWithoutContentTypeHeaders = new HttpHeaders({
      'Authorization': `Bearer ${this.authToken}`
    });

    this.httpOptionsAuth = {
      headers: this.httpOptionsAuthHeaders
    }
    this.httpOptionsAuthWithoutContentType = {
      headers: this.httpOptionsAuthWithoutContentTypeHeaders
    }
    this.httpOptions = {
      headers: this.httpOptionsHeaders
    }
  }



  protected handleError(error: any) {
    var applicationError = error.headers.get('Application-Error');

    // either applicationError in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';
    var serverError = error;

    if (!serverError.type) {
      for (var key in serverError.error) {
        if (serverError.error[key])
          modelStateErrors += serverError.error[key] + '\n';
      }
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }
}
