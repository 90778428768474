export class SetLogin {
  static readonly type = '[UserStateModel] SetLogin';
  constructor(public token: string) { }
}

export class SetLogout {
  static readonly type = 'SetLogout';
  constructor() { }
}

export class SetLanguageCode {
  static readonly type = 'SetLanguageCode';
  constructor(public languageCode: string) { }
}
