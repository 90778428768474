import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor } from '../helpers/error.interceptor';
import { TokenInterceptor } from '../helpers/token.interceptor';
import { MomentUtcDateAdapter } from '../utils/moment-utc-date-adapter';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { AuthGuard } from 'src/app/auth.guard';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FileSaverModule } from 'ngx-filesaver';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { GoogleMapsModule } from '@angular/google-maps';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { ConfigService } from '../services/config.service';

import { CallbackPipe } from '../pipes/callback.pipe';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { TiretAsNullPipe } from 'src/app/shared/pipes/tiret-as-null.pipe';
import { GridDisplayPipe } from 'src/app/shared/pipes/grid-display.pipe';
import { GridVisibleColumnsPipe } from 'src/app/shared/pipes/grid-visible-columns.pipe';
import { IsRowInSelectionPipe } from 'src/app/shared/pipes/is-row-in-selection.pipe';
import { CheckboxLabelPipe } from 'src/app/shared/pipes/checkbox-label.pipe';


import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ExportComponent } from 'src/app/shared/components/export/export.component';
import { EmailComponent } from 'src/app/shared/components/email/email.component';
import { DocumentUploadComponent } from 'src/app/shared/components/document-upload/document-upload.component';

@NgModule({
  declarations: [
    CallbackPipe,
    FilterPipe,
    TiretAsNullPipe,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    GridComponent,
    GridDisplayPipe,
    GridVisibleColumnsPipe,
    IsRowInSelectionPipe,
    CheckboxLabelPipe,
    ExportComponent,
    EmailComponent,
    DocumentUploadComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    FileSaverModule,
    FontAwesomeModule,
    PdfViewerModule,
    GoogleMapsModule,
    NgxDropzoneModule,
    QuillModule.forRoot()
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    CallbackPipe,
    TiretAsNullPipe,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    GridComponent,
    GridDisplayPipe,
    GridVisibleColumnsPipe,
    FontAwesomeModule,
    IsRowInSelectionPipe,
    CheckboxLabelPipe,
    PdfViewerModule,
    ExportComponent,
    EmailComponent,
    GoogleMapsModule,
    DocumentUploadComponent,
    NgxDropzoneModule,
    QuillModule
  ],
  providers: [ConfigService, AuthGuard, MomentDateAdapter,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
