import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/app/shared/store/user/user.state';
import { UserStateModel } from 'src/app/shared/models/user-state.model';
import { Language } from 'src/app/shared/models/language';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/shared/services/client.service';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isExpanded = false;
  status: boolean;
  languages: Language[] = [{ id: 0, code: 'fr', locale: 'fr', label: 'Français' }, { id: 1, code: 'gb', locale: 'en', label: 'English' }];
  language: Language;
  profile: string;
  @Select(UserState) userState$: Observable<UserStateModel>;
  @Output() nameEvent = new EventEmitter();
  isRequesting: boolean;
  logo: any;
  showDefaultLogo: boolean = false;
  logoRequestFinished: boolean = false;

  constructor(private userService: UserService, private clientService: ClientService,
    private translate: TranslateService, private router: Router, private store: Store, private consoleLoggerService: ConsoleLoggerService) {
    this.userState$.subscribe(
      (userData) => {
        //this.consoleLoggerService.log('userState subscribe', userData);
        this.status = userData.isLogged;
        if (!this.logo && userData.isLogged) {
          this.getLogo();
        }
      }
    );
  }

  getLogo() {
    this.isRequesting = true;

    this.clientService.getLogo()
      .pipe(finalize(() => this.isRequesting = false))
      .subscribe(
        result => {
          this.logoRequestFinished = true;
          if (result) {
            if (result.Message) {
              this.showDefaultLogo = true;
              this.consoleLoggerService.log('getLogoMessage');
            }
            this.consoleLoggerService.log('getLogo', result);
            this.logo = result;
          }
        }, error => {
          this.showDefaultLogo = true;
          this.consoleLoggerService.log('getLogo error', error);
        });
  }

  changeSourceGetDefault(event) {
    if (this.logoRequestFinished) {
      this.consoleLoggerService.log('changeSourceGetDefault', event);
      this.showDefaultLogo = true;
    }
  }

  ngOnInit() {
    this.useLanguage();
  }

  ngOnDestroy() {
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/accounts/home']);
  }

  useLanguage() {
    if (!this.language) {
      this.language = this.languages[0];
    }
    this.consoleLoggerService.log('useLanguage', this.language, this.languages);
    this.translate.use(this.language.locale);
  }

}
