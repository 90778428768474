import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'checkboxLabel',
  pure: true
})

export class CheckboxLabelPipe implements PipeTransform {
  transform(selected: boolean, deselectText: string, selectText: string): any {
    return selected == true ? deselectText : selectText;
  }
}
