import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  _apiURI: string;
  _authorizeLogging: boolean;

  constructor() {
  }

  getApiURI() {
    return this._apiURI;
  }

  getAuthorizeLogging() {
    return this._authorizeLogging;
  }
}
