import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Email } from 'src/app/shared/models/email.model';
import { ClientService } from 'src/app/shared/services/client.service';
import { finalize } from 'rxjs/operators';
import { cloneDeep } from "lodash";
import { MatButton } from '@angular/material/button';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  chipSelectable = true;
  chipRemovable = true;
  chipAddOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  isRequesting: boolean;
  errors: boolean;

  email: Email = new Email();
  files = [];

  @ViewChild('closeBtn', { static: true }) cb: MatButton;

  priorities = [
    { code: 'basse', libelle: 'Payeur.Contact.Email.Priority.Basse' },
    { code: 'normale', libelle: 'Payeur.Contact.Email.Priority.Normale' },
    { code: 'haute', libelle: 'Payeur.Contact.Email.Priority.Haute' }
  ];

  constructor(private clientService: ClientService, private consoleLoggerService: ConsoleLoggerService) { }

  ngOnInit(): void {
  }

  sendEmail() {
    this.errors = false;
    this.isRequesting = true;
    this.consoleLoggerService.log('sendEmail', this.email);
    
    this.email.pjs = cloneDeep(this.files);

    this.clientService.sendMail(this.email)
      .pipe(finalize(() => this.isRequesting = false))
      .subscribe(
        result => {
          if (result) {
            this.consoleLoggerService.log('sendEmail', result);
            this.cb._elementRef.nativeElement.click();
          }
        }, error => {
          this.errors = true;
          this.consoleLoggerService.log('sendEmail error', error);
        });
  }

  addChipTo(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our numero piece
    if ((value || '').trim()) {
      if (!this.email.to) {
        this.email.to = [];
      }
      value.trim().split(',').forEach(i => this.email.to.push(i));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeChipTo(to: string): void {
    const index = this.email.to.indexOf(to);

    if (index >= 0) {
      this.email.to.splice(index, 1);
    }
  }

  addChipCc(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our numero piece
    if ((value || '').trim()) {
      if (!this.email.cc) {
        this.email.cc = [];
      }
      value.trim().split(',').forEach(i => this.email.cc.push(i));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeChipCc(cc: string): void {
    const index = this.email.cc.indexOf(cc);

    if (index >= 0) {
      this.email.cc.splice(index, 1);
    }
  }


}
