import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Select, Store } from '@ngxs/store';
import { UserState } from '../store/user/user.state';
import { UserStateModel } from '../models/user-state.model';
import { UserInfos } from '../models/user-infos.model';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  error: string = '';
  @Select(UserState) state$: Observable<UserStateModel>;
  currentUser: UserInfos;
  isRefreshingToken: boolean = false;
  refreshTokenInProgress: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  retryCount: number = 0;

  constructor(private http: HttpClient, private userService: UserService, private router: Router,
    private toastr: ToastrService, private store: Store, private consoleLoggerService: ConsoleLoggerService) {
    this.state$.subscribe(
      (userData) => {
        //this.consoleLoggerService.log(userData);
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      this.handleAllErrors(err);

      if ((err.error instanceof Blob)) {
        this.error = '';
      }

      if (err.error && err.error.message && !(err.error instanceof Blob)) {
        this.error = err.error.message;
      } else if (this.error == '' && !(err.error instanceof Blob)) {
        this.error = err.statusText;
      }

      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 400:
            this.handle400Error();
            break;
          case 401:
            this.handle401Error(request, next);
            break;
          case 403:
            this.handle403Error();
            break;
          case 404:
            this.handle404Error();
            break;
          case 500:
            this.handle500Error();
            break;
          default:
            if (this.error != '') {
              this.showError();
            }
            break;
        }
      }

      this.error = '';

      return throwError(err);
    }))
  }

  private handle400Error() {
    // if 400 response returned from api
    this.showError();
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.disconnect();
  }

  private handle403Error() {
    // if 403 response returned from api
    this.showError();
  }

  private handle500Error() {
    // if 500 response returned from api
      this.showError();
  }

  private handle404Error() {
    // if 404 response returned from api
    this.showError();
  }

  private handleAllErrors(err: any) {
    if (err.error) {
      for (var key in err.error) {
        if (err.error[key])
          this.error += err.error[key] + '\n';
      }
    }
  }

  private disconnect() {
    // logout if ERROR response returned from api
    //this.showError();
    this.userService.logout();
    this.navigateToHomeWithoutURL();
  }

  private showError() {
    //this.toastr.error(this.error, null);
    //this.consoleLoggerService.log('HTTP Error');
  }

  private navigateToHome() {
    this.router.navigate(['/accounts/home'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    //$('.modal-backdrop').remove();
  }

  private navigateToHomeWithoutURL() {
    this.router.navigate(['/accounts/home']);
    //$('.modal-backdrop').remove();
  }

}
