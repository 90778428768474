import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/shared/store/user/user.state';
import { UserStateModel } from 'src/app/shared/models/user-state.model';
import { Observable } from 'rxjs';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { ClientService } from 'src/app/shared/services/client.service';
import { finalize } from 'rxjs/operators';
import { ConsoleLoggerService } from 'src/app/shared/services/console-logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Select(UserState) userState$: Observable<UserStateModel>;
  welcomeMessage: string = '';
  isRequesting: boolean;

  /**** FontAwesome Icons ****/
  faPowerOff = faPowerOff;
  /**** END FontAwesome Icons ****/

  constructor(private router: Router, private clientService: ClientService, private consoleLoggerService: ConsoleLoggerService) {
    this.userState$.subscribe(
      (userData) => {
        //this.consoleLoggerService.log('userState subscribe', userData);
        if (userData) {
          //this.consoleLoggerService.log('userState subscribe', userData);
        }
      }
    );
  }

  ngOnInit(): void {
    this.getWelcomeMessage();
  }

  getWelcomeMessage() {
    this.isRequesting = true;

    this.clientService.getWelcomeMessage()
      .pipe(finalize(() => this.isRequesting = false))
      .subscribe(
        result => {
          if (result) {
            this.consoleLoggerService.log('getWelcomeMessage');
            this.welcomeMessage = result;
          }
        }, error => {
          this.consoleLoggerService.log('getWelcomeMessage', error);
        });
  }

  enter(nePlusAfficher: boolean = false) {
    if (nePlusAfficher == true) {
      this.clientService.postWelcomeMessage()
        .pipe(finalize(() => this.isRequesting = false))
        .subscribe(
          result => {
            if (result) {
              this.consoleLoggerService.log('WelcomeMessageNoMore');
              this.router.navigate(['/espace-finance']);
            }
          }, error => {
            this.consoleLoggerService.log('getWelcomeMessage', error);
          });
    } else {
      this.router.navigate(['/espace-finance']);
    }
  }

}
