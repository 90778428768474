<div class="spinner-border spinner-border-sm text-secondary" role="status" *ngIf="loading">
  <span class="sr-only">{{'Loading' | translate}}</span>
</div>

<button class="button orange" mat-button [routerLink]="" matTooltip="{{ 'Payeur.Documents.Download.Title' | translate }}" *ngIf="!loading" (click)="downloadPDF()">
  <mat-icon>cloud_download</mat-icon> Download
</button>

<div #tablePDF>
  <table class="table table-striped" *ngIf="pdfSourceData && showData == true" width="100%">
    <colgroup>
      <col [width]="widthCol" *ngFor="let column of displayedColumns" />
    </colgroup>
    <thead>
      <tr class='warning'>
        <th *ngFor="let column of displayedColumns">{{column}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let result of pdfSourceData.results">
        <td *ngFor="let column of columns; let i = index">
          {{ result[i] | gridDisplay:'EN':column.propertyType}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
